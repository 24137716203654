<!-- <template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">Privacy Policy</div>
      <div class="con">本《隐私政策》适用于我们提供的一切adcsgo.cn服务。当您使用我们任何单项服务时，您同意接受本《隐私政策》以及我们在该单项服务中发出的特定隐私信息类政策条款（下列称“特定条款”）的保护，在此情况下特定条款与本政策条款同时对您产生效力。<br>
        请注意我们不时地会检查我们的政策，因此有关的措施会随之变化。我们恳请您定期光顾本页面，以确保对我们《隐私政策》最新版本始终保持了解。在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请与adcsgo.cn客服联系。<br>
        您使用或继续使用adcsgo.cn服务，都表示您同意我们按照本《隐私政策》收集、使用、储存和分享您的信息。<br>
        <h3>一、我们可能收集的信息</h3><br>
        （一）与个人身份无关的信息：<br>
        当您使用adcsgo.cn服务时，我们可能收集和汇总诸如用户的来源途径、访问顺序等信息，例如记录使用adcsgo.cn服务的每个用户的来源途径、浏览器软件等。<br>
        （二）有关个人身份的信息：<br>
        当您使用adcsgo.cn服务时，我们可能收集和汇总或要求您提供有关个人身份的信息，例如个人：生日、籍贯、性别、兴趣爱好、个人电话号码、（包括系统账号、IP地址、电子邮箱地址等）；个人财产信息（交易记录、余额、优惠券、兑换码等）；通讯信息；个人上网记录和日志信息（包括网站浏览记录、软件使用记录、点击记录、操作日志等）；设备信息（包括设备型号、设备MAC地址、操作系统类型、设备设置）；软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）；位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）。<br>
        我们收集您的信息主要是为了您和其他用户能够更容易和更满意地使用adcsgo.cn服务。adcsgo.cn的目标是向所有的互联网用户提供安全、刺激、有趣及有教益的上网经历。而这些信息有助于我们实现这一目标。<br>
        <h3>二、我们如何收集和使用信息</h3><br>
        （一）我们将通过以下途径收集和获得您的信息：<br>
        1、您提供的信息。 例如：<br>
        （1）您在注册adcsgo.cn服务的帐号或使用adcsgo.cn服务时，向我们提供的信息；<br>
        （2）您通过adcsgo.cn服务向其他方提供的共享信息，以及您使用adcsgo.cn服务时所储存的信息。<br>
        2、我们获取的您的信息。您在使用adcsgo.cn服务时，我们收集、汇总、记录的信息，例如日志信息、位置信息、设备信息等。<br>
        （二）COOKIES、日志档案和WEB BEACON<br>
        我们或我们的第三方合作伙伴可能通过COOKIES和WEB BEACON获取和使用您的信息，并将该等信息储存为日志信息。通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得adcsgo.cn虚拟社区的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录adcsgo.cn的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在adcsgo.cn特定服务上花费的时间和您所选择的adcsgo.cn服务。COOKIES使得我们能更好、更快地为您服务，并且使您在adcsgo.cn服务上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被你的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。<br>
        我们使用自己的COOKIES和WEB BEACON，用于以下用途：<br>
        （1）记住您的身份。例如：COOKIES和WEB BEACON有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；<br>
        （2）分析您使用我们服务的情况。我们可利用COOKIES和WEB BEACON来了解您使用adcsgo.cn服务进行什么活动、或哪些服务或服务最受欢迎；及<br>
        （3）广告优化。COOKIES和WEB BEACON有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。<br>
        我们为上述目的使用COOKIES和WEB BEACON的同时，可能将通过COOKIES和WEB BEACON收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用adcsgo.cn服务并用于广告服务。<br>
        adcsgo.cn服务上可能会有广告商和其他合作方放置的COOKIES和WEB BEACON。这些COOKIES和WEB BEACON可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES和WEB BEACON收集和使用该等信息不受本《隐私政策》约束，而是受到其自身的个人信息保护声明约束，我们不对第三方的COOKIES或WEB BEACON承担责任。<br>
        您可以通过浏览器或用户选择机制拒绝或管理COOKIES或WEB BEACON。但请您注意，如果您停用COOKIES或WEB BEACON，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到广告，只是这些广告与您的相关性会降低。<br>
        （三）我们会出于以下目的，收集和使用您的信息：<br>
        1、帮助您完成注册<br>
        为便于我们为您提供服务，您需要提供基本注册信息，例如手机号码、电子邮箱地址等，并创建您的用户名和密码。在部分单项服务中，如果您仅需使用浏览、搜索等基本服务，您不需要注册成为adcsgo.cn用户及提供上述信息。<br>
        2、向您提供商品或服务<br>
        我们所收集和使用的信息是为您提供adcsgo.cn服务的必要条件，如缺少相关信息，我们将无法为您提供adcsgo.cn服务的核心内容，例如：<br>
        （1）在部分服务项目中，为便于向您交付商品或服务，您需提供收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态等信息。如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过adcsgo.cn服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意。<br>
        （2）为展示您账户的订单信息，我们会收集您在使用adcsgo.cn服务过程中产生的订单信息用于向您展示及便于您对订单进行管理；<br>
        （3）当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。<br>
        （4）为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。<br>
        3、为您提供安全保障<br>
        为确保您身份真实性、向您提供更好的安全保障，您可以向我们提供身份证明、面部特征等生物识别信息等个人敏感信息以完成实名认证。<br>
        除身份验证外，我们可能将您的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途，确保我们向您提供的服务的安全性；我们可能使用或整合我们所收集的您的信息，以及我们的合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。<br>
        <h3>三、我们可能分享、转让或披露的信息</h3><br>
        （一）分享<br>
        除以下情形外，未经您同意，我们不会与adcsgo.cn之外的任何第三方分享您的信息：<br>
        1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：向物流服务商提供对应的订单信息；<br>
        2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以帮助我们为您提供更有针对性、更完善的服务，例如：代表我们发出电子邮件或推送通知的通讯服务提供商等；<br>
        3、实现本《隐私政策》第二条“我们如何收集和使用信息”部分所述目的；<br>
        4、履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；<br>
        5、向委托我们进行推广的合作伙伴等第三方共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供可以识别您身份的信息，例如姓名电话号码或电子邮箱；或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。<br>
        6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他adcsgo.cn用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息。<br>
        （二）转让<br>
        1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。<br>
        2、在获得您的明确同意后，我们会向其他方转让您的信息。<br>
        （三）披露<br>
        我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：
        1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
        2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。<br>
        <h3>四、我们如何保留、储存和保护信息</h3><br>
        我们将采取以下手段保护您的信息：<br>
        （一）数据安全技术措施<br>
        我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。 在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。<br>
        （二）我们为保护信息采取的其他安全措施<br>
        我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。<br>
        我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。<br>
        加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。<br>
        （三）我们仅允许有必要知晓这些信息的adcsgo.cn员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与adcsgo.cn的合作关系。<br>
        （四）我们会采取一切合理可行的措施，确保未收集无关的信息。<br>
        （五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。<br>
        （六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。<br>
        （七）安全事件处置<br>
        在通过adcsgo.cn服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。<br>
        为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。<br>
        在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。<br>
        请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入adcsgo.cn服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。<br>
        <h3>五、如何联系我们</h3><br>
        如您有关于网络信息安全的投诉和举报，或您对本《隐私政策》、您的信息的相关事宜有任何问题、意见或建议，以及有关本声明或adcsgo.cn的隐私措施的问题请与adcsgo.cn的协调人联系。
      </div><br>
    </div>
  </div>
</template> -->
<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">Privacy Policy</div>
      <div class="con">This Privacy Policy applies to all adcsgo.cn services we provide. When you use any of our individual services, you agree to be protected by this Privacy Policy and the specific privacy information policy terms (hereinafter referred to as "Specific Terms") issued by us in that individual service. In this case, the specific terms are the same as The terms of this policy are also effective for you. <br>
        Please note that we review our policy from time to time and the relevant measures may change accordingly. We kindly ask you to visit this page regularly to ensure you are always aware of the latest version of our Privacy Policy. After reading this policy, if you have any questions about this Privacy Policy or matters related to this Privacy Policy, please contact adcsgo.cn customer service. <br>
        Your use or continued use of adcsgo.cn services indicates that you agree to our collection, use, storage and sharing of your information in accordance with this Privacy Policy. <br>
        <h3>1. Information we may collect</h3><br>
        (1) Information unrelated to personal identity:<br>
        When you use the adcsgo.cn service, we may collect and summarize information such as the user's source path, access sequence, etc., such as recording the source path, browser software, etc. of each user who uses the adcsgo.cn service. <br>
        (2) Personal identity information:<br>
        When you use adcsgo.cn services, we may collect and aggregate or ask you to provide personal identity information, such as personal: birthday, place of origin, gender, interests and hobbies, personal phone number, (including system account number, IP address, email address address, etc.); personal property information (transaction records, balances, coupons, redemption codes, etc.); communication information; personal Internet access records and log information (including website browsing records, software usage records, click records, operation logs, etc.); equipment information (Including device model, device MAC address, operating system type, device settings); software list unique device identification code (such as IMEI/android ID/IDFA/OPENUDID/GUID, SIM card IMSI information, etc.), describing the basic situation of personal commonly used devices information); location information (including itinerary information, precise positioning information, accommodation information, longitude and latitude, etc.). <br>
        We collect your information mainly so that you and other users can use adcsgo.cn services more easily and satisfactorily. The goal of adcsgo.cn is to provide all Internet users with a safe, exciting, interesting and educational online experience. And this information helps us achieve this goal. <br>
        <h3>2. How we collect and use information</h3><br>
        (1) We will collect and obtain your information through the following channels:<br>
        1. Information you provide. For example:<br>
        (1) The information you provide to us when registering an account for the adcsgo.cn service or using the adcsgo.cn service;<br>
        (2) The shared information you provide to other parties through the adcsgo.cn service, and the information stored when you use the adcsgo.cn service. <br>
        2. Your information we obtain. When you use adcsgo.cn services, we collect, summarize, and record information, such as log information, location information, device information, etc. <br>
        (2) COOKIES, log files and WEB BEACON<br>
        We or our third-party partners may obtain and use your information through COOKIES and WEB BEACON, and store such information as log information. By using COOKIES, we provide users with a simple, easy and personalized network experience. A COOKIES is a small amount of data that is sent to your browser from a web server and stored on your computer's hard drive. We use COOKIES to benefit its users. For example, to make the login process of adcsgo.cn virtual community faster, you can choose to save your username in a COOKIES. This will make it more convenient and faster next time you want to log in to adcsgo.cn's services. COOKIES can help us determine the pages and content you are connected to, the time you spend on adcsgo.cn specific services and the adcsgo.cn service you have chosen. COOKIES allow us to serve you better and faster and make your experience on the adcsgo.cn service more personalized. However, you should be able to control whether and how COOKIES are accepted by your browser. Please consult the documentation provided with your browser for more information on this. <br>
        We use our own COOKIES and WEB BEACON for the following purposes:<br>
        (1) Remember your identity. For example: COOKIES and WEB BEACON help us identify you as our registered user, or save your preferences or other information you provide us;<br>
        (2) Analyze your use of our services. We can use COOKIES and WEB BEACON to understand what activities you use adcsgo.cn services for, or which services or services are the most popular; and<br>
        (3) Advertising optimization. COOKIES and WEB BEACON help us provide you with advertisements that are relevant to you based on your information instead of general advertising. <br>
        While we use COOKIES and WEB BEACON for the above purposes, we may provide aggregate non-personally identifiable information collected through COOKIES and WEB BEACON to advertisers and other partners to analyze how you and other users use adcsgo.cn services and use them for advertising. Serve. <br>
        There may be COOKIES and WEB BEACON placed by advertisers and other partners on the adcsgo.cn service. These COOKIES and WEB BEACON may collect non-personally identifiable information related to you to analyze how users use such services, send you advertisements that may be of interest to you, or to evaluate the effectiveness of advertising services. The collection and use of such information by these third-party COOKIES and WEB BEACON is not subject to this Privacy Policy, but is subject to its own personal information protection statement. We are not responsible for the third-party COOKIES or WEB BEACON. <br>
        You can refuse or manage COOKIES or WEB BEACONs through your browser or user selection mechanism. However, please note that if you disable COOKIES or WEB BEACON, we may not be able to provide you with the best service experience, and some services may not work properly. At the same time, you will still receive ads, they will just be less relevant to you. <br>
        (3) We will collect and use your information for the following purposes:<br>
        1. Help you complete registration<br>
        In order for us to provide you with services, you need to provide basic registration information, such as mobile phone number, email address, etc., and create your username and password. In some individual services, if you only need to use basic services such as browsing and searching, you do not need to register as an adcsgo.cn user and provide the above information. <br>
        2. Provide you with goods or services<br>
        The information we collect and use is necessary to provide you with adcsgo.cn services. Without relevant information, we will not be able to provide you with the core content of adcsgo.cn services, such as:<br>
        (1) In some services, in order to facilitate the delivery of goods or services to you, you need to provide the consignee's personal identity information, name, delivery address, postal code, consignee, contact number, payment status and other information. If you refuse to provide such information, we will not be able to complete the relevant delivery services. If you order goods or services for others through the adcsgo.cn service, you need to provide the aforementioned information of the actual orderer. Before providing us with the aforementioned information of the actual orderer, you need to ensure that you have obtained his or her authorization. <br>
        (2) In order to display the order information of your account, we will collect the order information generated during your use of adcsgo.cn services for display to you and to facilitate your order management;<br>
        (3) When you contact us, we may save your communication/call records and content or the contact information you left and other information in order to contact you or help you solve the problem, or record the solution to the relevant problem and result. <br>
        (4) In order to confirm the transaction status and provide you with after-sales and dispute resolution services, we will collect your transaction, payment, and logistics information related to the transaction progress through the transaction partners, payment institutions, logistics companies, etc. you choose based on the exchange. Or share your transaction information with the above service providers. <br>
        3. Provide you with security<br>
        In order to ensure the authenticity of your identity and provide you with better security, you can provide us with personal sensitive information such as identity certificate, facial features and other biometric information to complete real-name authentication. <br>
        In addition to identity verification, we may use your information for customer service, security prevention, fraud monitoring, archiving and backup, etc. to ensure the security of the services we provide you; we may use or integrate your information we have collected. Information, as well as information that our partners have obtained your authorization or shared in accordance with the law, to comprehensively judge your account and transaction risks, conduct identity verification, detect and prevent security incidents, and take necessary recording, auditing, analysis, and disposal measures in accordance with the law. <br>
        <h3>3. Information we may share, transfer or disclose</h3><br>
        (1) Share<br>
        Except for the following circumstances, we will not share your information with any third party other than adcsgo.cn without your consent:<br>
        1. Provide you with our services. We may share your information with partners and other third parties to implement the core functions you need or provide the services you need, such as providing corresponding order information to logistics service providers;<br>
        2. Maintain and improve our services. We may share your information with partners and other third parties to help us provide you with more targeted and improved services, such as communication service providers who send emails or push notifications on our behalf;<br>
        3. To achieve the purposes described in the "How We Collect and Use Information" section of Article 2 of this "Privacy Policy";<br>
        4. Fulfill our obligations and exercise our rights in this Privacy Policy or other agreements we have reached with you;<br>
        5. Share with third parties such as partners who entrust us with promotion, but we will only provide these entrusting parties with information on the coverage and effectiveness of the promotion, and will not provide information that can identify you, such as your name, phone number or email address. Email; or we aggregate this information so that it does not identify you personally. For example, we can tell the entrusting party how many people have read their promotional information or purchased the entrusting party's products after seeing this information, or provide them with unidentifiable statistical information to help them understand their audiences or customers. <br>
        6. To the extent permitted by laws and regulations, in order to comply with the law, protect us and our affiliates or partners, you or other adcsgo.cn users or the public's interests, property or safety from damage, such as to prevent fraud and other illegal activities activities and to reduce credit risk, we may exchange information with other companies and organizations. However, this does not include information that is sold, rented, shared or otherwise disclosed for profit in violation of the commitments made in this Privacy Policy. <br>
        (2) Transfer<br>
        1. As our business continues to develop, we may engage in mergers, acquisitions, asset transfers or similar transactions, and your information may be transferred as part of such transactions. We will require new companies and organizations that hold your information to continue to be bound by this Privacy Policy. Otherwise, we will require the company or organization to seek your authorization and consent again. <br>
        2. After obtaining your explicit consent, we will transfer your information to other parties. <br>
        (3) Disclosure<br>
        We will only disclose your information under the following circumstances and under the premise of taking security measures that comply with industry standards:
        1. According to your needs, disclose the information you specify in the disclosure method you explicitly agree to;
        2. When it is necessary to provide your information in accordance with the requirements of laws, regulations, mandatory administrative enforcement or judicial requirements, we may disclose your information based on the required information type and disclosure method. Subject to complying with laws and regulations, when we receive the above request to disclose information, we will require the recipient to issue corresponding legal documents, such as a subpoena or a letter of investigation. We firmly believe that the information we are asked to provide should be as transparent as possible to the extent permitted by law. All requests are carefully reviewed to ensure they have a legitimate basis and are limited to data that law enforcement has a legal right to obtain for specific investigative purposes. <br>
        <h3>4. How we retain, store and protect information</h3><br>
        We will take the following measures to protect your information:<br>
        (1) Data security technical measures<br>
        We will adopt security protection measures that comply with industry standards, including establishing reasonable system specifications and security technologies to prevent your information from unauthorized access, use, modification, and avoid data damage or loss. Network services use a variety of encryption technologies. For example, in some services, we will use encryption technology (such as SSL) to protect your information, use encryption technology to encrypt and save your information, and isolate it through isolation technology. When using information, such as information display and information correlation calculation, we will use a variety of data desensitization technologies to enhance the security of information in use. Strict data access control and multi-identity authentication technology are used to protect information and prevent data from being used illegally. <br>
        (2) Other security measures we take to protect information<br>
        We manage the storage and use of standardized information by establishing data classification and classification systems, data security management specifications, and data security development specifications. <br>
        We implement comprehensive security controls on data through information contact confidentiality agreements, monitoring and auditing mechanisms. <br>
        Strengthen safety awareness. We will also hold security and privacy protection training courses to enhance employees' awareness of the importance of protecting information. <br>
        (3) We only allow adcsgo.cn employees and partners who need to know this information to access your information, and have set up strict access control and monitoring mechanisms for this purpose. We also require all persons who may have access to your information to fulfill corresponding confidentiality obligations. Failure to fulfill these obligations may result in legal liability or suspension of the partnership with adcsgo.cn. <br>
        (4) We will take all reasonable and feasible measures to ensure that no irrelevant information is collected. <br>
        (5) The Internet is not an absolutely secure environment, and it is impossible to determine whether the communication methods with other users such as email, instant messaging, social software or other service software are fully encrypted. We recommend that you use complex passwords when using such tools, and Pay attention to protecting the security of your information. <br>
        (6) The Internet environment is not 100% safe, and we will try our best to ensure or guarantee the security of any information you send us. If our physical, technical, or management protection facilities are damaged, resulting in unauthorized access, public disclosure, tampering, or destruction of information, resulting in damage to your legitimate rights and interests, we will bear corresponding legal liability. <br>
        (7) Security incident handling<br>
        When communicating with a third party or purchasing goods and services through the adcsgo.cn service, you will inevitably have to disclose your information, such as contact information or postal address, to the counterparty or potential counterparty. Please protect your information properly and provide it to others only when necessary. <br>
        In order to deal with possible risks such as information leakage, damage and loss, we have formulated a number of systems to clarify the classification and grading standards of security incidents and security vulnerabilities and the corresponding processing procedures. We have also established a dedicated emergency response team for security incidents. In accordance with the requirements of security incident handling specifications, we launch security plans for different security incidents, stop losses, analyze, locate, formulate remedial measures, and cooperate with relevant departments to trace the source and attack. <br>
        After an information security incident unfortunately occurs, we will promptly inform you in accordance with the requirements of laws and regulations: the basic situation and possible impact of the security incident, the handling measures we have taken or will take, and suggestions for you to prevent and reduce risks on your own. , remedial measures for you, etc. We will also promptly notify you of the relevant information through emails, letters, phone calls, push notifications, etc. When it is difficult to inform the information subjects one by one, we will issue announcements in a reasonable and effective manner. At the same time, we will also proactively report the handling of information security incidents in accordance with the requirements of regulatory authorities. <br>
        Please understand that due to technical limitations and risk prevention limitations, even if we have tried our best to strengthen security measures, we cannot always guarantee 100% security of information. You need to understand that the system and communication network you use to access the adcsgo.cn service may have problems due to circumstances beyond our control. <br>
        <h3>5. How to contact us</h3><br>
        If you have complaints or reports about network information security, or if you have any questions, comments or suggestions about this Privacy Policy, your information, or any questions about this statement or the privacy measures of adcsgo.cn, please contact adcsgo Contact the coordinator of .cn.
      </div><br>
    </div>
  </div>
</template>
<script>
export default {

};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    padding-bottom: 88px;
    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }
}
</style>
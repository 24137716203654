<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">About Us</div>
      <div class="con"><span> On the Pxiubox website, you can get more interesting items instantly.</span><br>
        <!-- <span>518csgo.com是国内货涵盖全面的CSGO饰品交易品牌。518csgo.cn网站上，你可以即时获得更多的csgo的物品，adcsgo.cn特惠商城有海量的商品可随时取回到库存，其库存量没有任何一家同类型网站可以比拟。<br></span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
    // padding-top: 88px;
    // background-color: #2b2c37;
    padding: 32px;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    span {
      display: inline-block;
      margin-top: 10px;
    }
  }
}
</style>
<template comments>
  <div
    class="pay"
    :style="{
      backgroundImage: '/img/lucky/b825f4845a9b5ef3d3908882b3c3b87.png '
    }"
  >
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
    <div class="pay-warp">
      <div class="pay-hint">
        <div class="pay-hint-left">
        </div>
        <div class="payBill" @click="goToBill()">my bill</div>
        <div class="tMsg">
          If you are not satisfied with the service, please contact customer service first. If the problem is not resolved, please contact  <span @click="concact()"
            >customer service>></span
          >
        </div>
      </div>
      <div class="pay-ment">
        <el-tabs tab-position="top" v-model="payType">
          <el-tab-pane label="Stripe" name="alipay">
            <div class="ment-one">
              <div></div>
            </div>
            <div class="ment-two">
              <img style="height: 50px" src="../assets/img/stripe.png" />
              <span>Online Payment</span>
            </div>
            <div class="ment-three" v-if="firstGiveValidate">
              The recharge will arrive within 10 minutes
            </div>
            <div class="ment-three" v-else>The recharge will arrive within 10 minutes</div>
            <div class="ment-list" v-if="showNav">
              <ul>
                <li
                  v-for="(item, index) in list.slice(0, list.length - 1)"
                  :key="index"
                  @click="zhifuMoney(index)"
                  :class="item.id ? '' : 'list-li'"
                >
                  <div class="ment-state" v-if="item.state"></div>

                  <div
                    class="give"
                    v-if="firstGiveValidate && item.is_first_give && is_new"
                  >
                  Get more for first deposit{{
                      ((Number(item.money) * firstGive.billie) / 100).toFixed(
                        2
                      )
                    }}Z
                  </div>
                  <div v-else>
                    <div class="give" v-if="item.billieState">
                      Send more {{
                        ((Number(item.money) * item.billie) / 100).toFixed(2)
                      }}
                    </div>
                  </div>

                  <!-- <div class="list-top" :class="'img-r'+index" v-if="item.img && (list.length/(index+1) != 1)"> -->
                  <div class="list-top" :class="!item.id ? 'no-p' : ''">
                    <img :src="item.img" />
                  </div>
                  <div class="list-warp" v-if="item.id">
                    <div class="list-con">{{ item.money }}</div>
                    <!-- <div class="list-bot">≈ $ {{ item.rmb }}</div> -->
                  </div>

                  <div class="list-warp" v-if="!item.id">
                    <div
                      class="list-top list-top1"
                      :class="!item.id ? 'no-p-1' : ''"
                    >
                      0
                    </div>
                    <div class="list-input">
                      <span class="list-span1" @click="remInput">-</span>
                      <el-input
                        class="input"
                        @input="getInput"
                        type="number"
                        v-model="item.money"
                      />
                      <span class="list-span2" @click="addInput">+</span>
                    </div>
                    <div class="list-bot">≈ $ {{ item.rmb }}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="ment-listm" v-else>
              <ul>
                <li
                  v-for="(item, index) in list.slice(0, list.length - 1)"
                  :key="index"
                  @click="zhifuMoney(index)"
                  :class="item.id ? '' : 'list-li'"
                >
                  <div class="ment-state" v-if="item.state"></div>

                  <div
                    class="give"
                    v-if="firstGiveValidate && item.is_first_give && is_new"
                  >
                  Get more for first deposit{{
                      ((Number(item.money) * firstGive.billie) / 100).toFixed(
                        2
                      )
                    }}Z币
                  </div>
                  <div v-else>
                    <div class="give" v-if="item.billieState">
                      Send more{{
                        ((Number(item.money) * item.billie) / 100).toFixed(2)
                      }}
                    </div>
                  </div>

                  <!-- <div class="list-top" :class="'img-r'+index" v-if="item.img && (list.length/(index+1) != 1)"> -->
                  <div class="list-top" :class="!item.id ? 'no-p' : ''">
                    <img :src="item.img" />
                  </div>
                  <div class="list-warp" v-if="item.id">
                    <div class="list-con">{{ item.money }}</div>
                    <div class="list-bot">≈ $ {{ item.rmb }}</div>
                  </div>

                  <div class="list-warp" v-if="!item.id">
                    <div
                      class="list-top list-top1"
                      :class="!item.id ? 'no-p-1' : ''"
                    >
                      其他数量
                    </div>
                    <div class="list-input">
                      <span class="list-span1" @click="remInput">-</span>
                      <el-input
                        class="input"
                        @input="getInput"
                        type="number"
                        v-model="item.money"
                      />
                      <span class="list-span2" @click="addInput">+</span>
                    </div>
                    <div class="list-bot">≈ $ {{ item.rmb }}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="ment-btn">
              <el-button
                class="el-btn"
                :disabled="loading"
                :style="{ 'background-color': loading ? '#949493' : '#e9b10e' }"
                @click="payInfo('stripe')"
                ><i v-if="loading" class="el-icon-loading"></i>Recharge now
              </el-button>
              <!--
                <el-button
                class="el-btn"
                :disabled="loading"
                :style="{ 'background-color': loading ? '#949493' : '#e9b10e' }"
                @click="payInfo('test')"
                ><i v-if="loading" class="el-icon-loading"></i>Recharge now
              </el-button> -->
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog
      :visible.sync="payBox"
      width="320px"
      center
      top="5%"
      :before-close="handleClose"
    >
      <div slot="title" class="dialog-title">
        <div>
          <img :src="require('../assets/img/' + payTitleIcon)" />
          <span class="title-text">{{ payTitle }}</span>
        </div>
      </div>
      <div style="min-height: 160px">
        <div id="qrcode" ref="qrcode" style="margin-left: 60px" />
      </div>
      <div class="save-qrcode">
        <el-button type="warning" @click="saveImg">Save QR code</el-button>
        <el-button type="warning" @click="copyToClipboard"
          >Copy payment link</el-button
        >
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <img src="../assets/img/scan.svg" />
          <div>
            <p>{{ payFooter }}</p>
            <p>{{ payFooterDesc }}</p>
          </div>
        </div>
        <div style="font-size: 14px;line-height:20px;margin-top:20px;"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import navheader from "@/components/navheader.vue";

export default {
  components: {
    navheader,
  },
  props: ["yidongmenu", "showNav", "openHongbao", "img"],
  data() {
    return {
      loading: false,
      payTitle: "",
      payTitleIcon: "wechat.svg",
      payFooter: "",
      payFooterDesc: "",
      payBox: false,
      payUrl: "",
      cdk: "",
      payType: "alipay",
      list: [],
      payObj: {
        rmb: 0,
        money: 5,
        state: false,
      },
      rmb: 0,
      ratioList: [],
      firstGiveValidate: false,
      ordinaryValidate: false,
      firstGive: {}, 
      ordinaryActivity: {},
      is_new: false, 
      userInfo: {},
      payInfoData: {
        flag: false,
        type: "",
        checked2: true,
        checked1: true,
      },
    };
  },
  mounted() {
    this.payBox = false;
    this.amountList();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userInfo = userInfo;
    console.log(this.userInfo);
    if (this.$route.query.type) {
      this.payType = this.$route.query.type;
    }
  },
  methods: {
    getRatio() {
      this.$axios.get("index/User/giveAboutRecharge").then((res) => {
        let data = res.data;
        console.log(data);
        if (data.status == 1) {
          this.ratioList = data.data;
        }
      });
    },
    goToBill() {
      this.$router.push({
        path: `/Bill`,
      });
    },
    concact() {
      window.open(
        "https://tawk.to/chat/66152b841ec1082f04e072de/1hr1b40d5"
      );
    },
    getInput(value) {
      //  /^(0\.0[1-9]|0\.[1-9]\d|[1-9]\d?(\.\d\d)?|[1-4]\d\d(\.\d\d)?|500)$/
      if (value == "") {
        this.list[6].money = "";
        this.list[6].rmb = 0;
      } else {
        this.list[6].money = value;
        this.list[6].rmb = (value * this.rmb).toFixed(2);
      }
      value = Number(value);

      let money = Number(this.ratioList.money);
      let limit = Number(this.ratioList.limit);
      if (money > 0 && limit > 0) {
        if (value >= money && value <= limit) {
          this.setTrue();
        } else {
          this.setFalse();
        }
      } else if (money == 0 && limit == 0) {
        this.setTrue();
      } else if (money > 0 && limit == 0) {
        console.log(value >= money);
        if (value >= money) {
          this.setTrue();
        } else {
          this.setFalse();
        }
      } else if (money == 0 && limit > 0) {
        if (value <= limit) {
          this.setTrue();
        } else {
          this.setFalse();
        }
      } else {
        this.setFalse();
      }
    },
    CDKInput(e) {
      this.CDK = e.detail.value;
    },
    setTrue() {
      this.list[this.list.length - 1].is_first_give = true;
      this.list[this.list.length - 1].billieState = true;
      this.list[this.list.length - 1].billie = this.ratioList.billie;
    },
    setFalse() {
      this.list[this.list.length - 1].is_first_give = false;
      this.list[this.list.length - 1].billieState = false;
      this.list[this.list.length - 1].billie = 0;
    },
    addInput() {
      if (this.list[6].money < 5) {
        this.list[6].money = 5.0;
        this.list[6].rmb = 5.0 * this.rmb;
      } else {
        this.list[6].money = (Number(this.list[6].money) + 1).toFixed(2);
        this.list[6].rmb = (this.list[6].money * this.rmb).toFixed(2);
      }
      // console.log(11)
      for (let i = 0; i < this.ratioList.length; i++) {
        if (this.list[6].money >= Number(this.ratioList[i].money)) {
          this.list[6].billie = this.ratioList[i].billie;
          this.list[6].billieState = true;
        } else {
          this.list[6].billieState = false;
        }
      }
    },
    remInput() {
      if (this.list[6].money <= 5) {
        return;
      }
      this.list[6].money = ((this.list[6].money * 100 - 1 * 100) / 100).toFixed(
        2
      );
      this.list[6].rmb = (this.list[6].money * this.rmb).toFixed(2);
      for (let i = 0; i < this.ratioList.length; i++) {
        if (this.list[6].money >= Number(this.ratioList[i].money)) {
          this.list[6].billie = this.ratioList[i].billie;
          this.list[6].billieState = true;
        } else {
          this.list[6].billieState = false;
        }
      }
    },
    amountList() {
      let param = {
        player_id: localStorage.getItem("token"),
      };
      this.$axios
        .post("index/User/giveAboutRecharge", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          this.is_new = data.data.new;
          if (data.status == 1) {
            // this.ratioList = data.data.giveInfo;
            // if(data.data.firstGive){
            //   this.firstGiveValidate = true;
            //   this.firstGive = data.data.firstGive;
            // }
            if (this.is_new) {
              if (data.data.recharge_activity.length > 0) {
                data.data.recharge_activity.forEach((e) => {
                  if (e.type == 1) {
                    this.firstGiveValidate = true;
                    this.ratioList = e;
                    this.firstGive = e;
                  }
                  if (e.type == 2) {
                    this.firstGiveValidate = false;
                    this.ratioList = e;
                    this.ordinaryActivity = e;
                  }
                });
              }
            } else {
              this.firstGiveValidate = false;
              if (data.data.recharge_activity.length > 0) {
                data.data.recharge_activity.forEach((e) => {
                  if (e.type == 2) {
                    this.ratioList = e;
                    this.ordinaryActivity = e;
                  }
                });
              }
            }
          }
          this.$axios.get("/index/pay/chargeInfoList").then((res) => {
            let data = res.data;
            if (data.status == 1) {
              this.rmb = data.data.exchange_rate;
              this.payObj.rmb = data.data.exchange_rate * 5;
              let list = res.data.data.list;
              list.push(this.payObj);
              list.forEach(function (item, index) {
                item.state = false;
                if (index == 0) {
                  item.state = true;
                }
              });
              this.list = list;
              console.log(this.list);
              for (let i = 0; i < this.list.length; i++) {
                let money = Number(this.list[i].money); 
                let lower_limit = Number(this.ratioList.money);
                let upper_limit = Number(this.ratioList.limit);
                console.log(money,lower_limit,upper_limit);
                if (lower_limit > 0 && upper_limit > 0) {
                  if (money >= lower_limit && money <= upper_limit) {
                    this.list[i].billie = this.ratioList.billie;
                    this.list[i].billieState = true;
                  }
                }
                if (lower_limit == 0 && upper_limit == 0) {
                  this.list[i].billie = this.ratioList.billie;
                  this.list[i].billieState = true;
                }
                if (lower_limit > 0 && upper_limit == 0) {
                  if (money >= lower_limit) {
                    this.list[i].billie = this.ratioList.billie;
                    this.list[i].billieState = true;
                  }
                }
                if (lower_limit == 0 && upper_limit > 0) {
                  if (money <= upper_limit) {
                    this.list[i].billie = this.ratioList.billie;
                    this.list[i].billieState = true;
                  }
                }
              }
            }
          });
          // console.log(this.ratioList);
          // console.log(this.ordinaryActivity);
          if (!this.empty(this.ordinaryActivity)) {
            this.ordinaryValidate = true;
          }
        });
    },
    empty(obj) {
      for (let key in obj) {
        return false;
      }
      return true; 
    },
    epayjump() {
      this.$router.push = "https://www.pxiubox.com/SDK/index.php";
    },
    payInfo(t) {
      let money = 0;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state) {
          money = this.list[i].money;
        }
      }
      this.payAction(t);

      // if (this.userInfo.total_amount == "0.00" || money > 199) {
      //   this.payInfoData.flag = false;
      //   this.payInfoData.type = t;
      // } else {
      //   this.payAction(t);
      // }
    },
    checkPayInfo() {
      if (this.payInfoData.checked1 && this.payInfoData.checked2) {
        if (this.payInfoData.type) {
          this.payInfoData.flag = false;
          this.payAction(this.payInfoData.type);
        }
      } else {
        this.$message.info("....");
      }
    },
    payAction(t) {

      if (t == "stripe") {
        this.loading = true;
        this.payTitle = "Stripe";
        this.pay("stripe");
        return;
      }

    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    pay(type) {
      console.log(this.$store.state.id,this.$store.state.mobile)
      let money = 0;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state) {
          money = this.list[i].money;
        }
      }

      let data = {
        mode: type,
        money: money,
        // player_id: this.$store.state.token,
        mobile: this.userInfo.mobile,
      };
      console.log(data);
      this.$axios.post("/index/Pay/recharge", data).then((res) => {
        // window.open(res.data.data);
        window.location.href = res.data.data;
        // if (type == "stripe") {
        //   this.payBox = true;
        //   this.loading = false;
        //   this.$nextTick(() => {
        //     const qrCode = new QRCode("qrcode", {
        //       width: 160,
        //       height: 160,
        //       text: res.data.data,
        //     });
        //     this.payUrl = res.data.data;
        //   });
        //   return;
        // }
        return;
      });
    },
    handleClose() {
      this.payBox = false;
      this.$refs.qrcode.innerHTML = "";
    },
    saveImg() {
      var canvasData = this.$refs.qrcode.getElementsByTagName("canvas");
      var a = document.createElement("a");
      var event = new MouseEvent("click");
      a.href = canvasData[0].toDataURL("image/png");
      a.download = "Pay QR code";
      a.dispatchEvent(event);
    },
    weixinMoney(index) {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
      }
      this.list[index].state = true;
    },
    zhifuMoney(index) {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
      }
      this.list[index].state = true;
    },
    epayMoney(index) {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
      }
      this.list[index].state = true;
    },
  },
};
</script>

<style lang="less" scoped>
.payinfoBox {
  /deep/ .el-dialog__title {
    color: #808085;
  }

  /deep/ h3 {
    color: #eaeaea;
    font-weight: normal;
    margin-bottom: 16px;
  }

  /deep/ .el-checkbox {
    color: #17b4ed;
  }

  /deep/ .btn-area {
    text-align: center;
    padding: 16px 0 8px 0;
  }
}

.pay {
  width: 100%;
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  /* 兼容Opera */
  zoom: 1;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  min-height: 100vh;
  background-color: #1a1c24;
  padding-bottom: 24px;

  .pay-warp {
    padding: 16px;
  }

  .pay-title {
    font-size: 20px;
    color: #c3c3e2;
  }

  .pay-hint {
    margin-top: 10px;
    text-align: right;

    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    .payBill {
      font-size: 20px;
      color: #848492;
      line-height: 1;
      cursor: pointer;
      margin-bottom: 12px;

      :hover {
        color: #e9b10e;
      }
    }

    .tMsg {
      color: #eaeaea;
      font-size: 14px;

      span {
        color: #17b4ed;
        cursor: pointer;
      }
    }

    .pay-hint-left {
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        color: #848492;
      }

      img {
        width: auto;
        height: 16px;
      }
    }

    .pay-hint-right {
      font-size: 16px;
      color: #848492;
    }

    .pay-hint-right:hover {
      cursor: pointer;
      color: #e9b10e;
    }
  }

  .pay-ment {
    margin-top: 30px;

    .ment-one {
      display: flex;
      justify-content: space-between;

      span {
        color: #17b4ed;
      }

      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .ment-two {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 32px;
        color: #c3c3e2;
        margin-left: 15px;
      }

      img {
        // width: 50px;
        height: auto;
      }
    }

    .ment-three {
      margin-top: 20px;
      text-align: center;
      font-size: 16px !important;
      color: #e9b10e;
    }

    .ment-four {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 20px;
        color: #c3c3e2;
        margin-left: 15px;
      }
    }

    .ment-five {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 20px;
        color: #c3c3e2;
        margin-left: 15px;
      }
    }

    .ment-list {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      ul {
        // display: flex;
        //flex-wrap: wrap;

        li {
          float: left;
          width: 145px;
          margin: 5px;
          background-color: #333542;
          height: min-content;
          border-radius: 5px;
          position: relative;

          .list-top {
            text-align: center;
            margin: 35px auto 10px;
            width: 117px;
            height: 117px;
            position: relative;

            img {
              width: 100%;
              max-height: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }

          .list-warp {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // padding-left: 40PX;
            // padding-right: 40px;
            padding-bottom: 20px;

            .list-top1 {
              font-size: 14px;
              color: #c3c3e2;
              margin: 60px auto 0;
              height: 68px;
            }

            .list-input {
              padding-left: 10px;
              padding-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
              position: relative;
              z-index: 66;

              .input /deep/ .el-input__inner {
                max-width: 50px;
                padding: 0;
                color: #e9b10e;
                background-color: #333542;
                height: 30px;
                line-height: 30px;
              }

              span {
                font-size: 30px;
                color: #848492;
              }

              .list-span1 {
                margin-right: 20px;
              }

              .list-span2 {
                margin-left: 10px;
              }
            }

            .list-con {
              text-align: center;
              // margin-top: 30px;
              color: #e9b10e;
              font-size: 20px;
            }

            .list-bot {
              text-align: center;
              margin-top: 10px;
              font-size: 14px;
              color: #848492;
              white-space: nowrap;
            }
          }

          .ment-state {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            border: 1px solid #e9b10e;
            background-color: rgba(233, 177, 14, 0.1);
          }

          .give {
            position: absolute;
            top: 1px;
            left: 0;
            width: 100%;
            height: 32px;
            line-height: 32px;
            margin-left: 1px;
            background-color: #e9b10e;
            color: #1a1c24;
            font-size: 13px;
            text-align: center;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }
        }

        .list-li {
          // padding: 40px 20px 20px 20px;
        }

        li:hover {
          cursor: pointer;
        }
      }
    }

    .ment-listm {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      ul {
        // display: flex;
        //flex-wrap: wrap;

        li {
          float: left;
          width: 145px;
          margin: 5px;
          background-color: #333542;
          height: min-content;
          border-radius: 5px;
          position: relative;

          .list-top {
            text-align: center;
            margin: -20px auto 10px;
            width: 77px;
            height: 117px;
            position: relative;

            img {
              width: 100%;
              max-height: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }

          .list-warp {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // padding-left: 40PX;
            // padding-right: 40px;
            padding-bottom: 20px;

            .list-top1 {
              font-size: 14px;
              color: #c3c3e2;
              margin: 60px auto 0;
              height: 68px;
            }

            .list-input {
              padding-left: 10px;
              padding-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
              position: relative;
              z-index: 66;

              .input /deep/ .el-input__inner {
                max-width: 50px;
                padding: 0;
                color: #e9b10e;
                background-color: #333542;
                height: 30px;
                line-height: 30px;
              }

              span {
                font-size: 30px;
                color: #848492;
              }

              .list-span1 {
                margin-right: 20px;
              }

              .list-span2 {
                margin-left: 10px;
              }
            }

            .list-con {
              text-align: center;
              // margin-top: 30px;
              color: #e9b10e;
              font-size: 20px;
            }

            .list-bot {
              text-align: center;
              margin-top: 10px;
              font-size: 14px;
              color: #848492;
              white-space: nowrap;
            }
          }

          .ment-state {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            border: 1px solid #e9b10e;
            background-color: rgba(233, 177, 14, 0.1);
          }

          .give {
            position: absolute;
            top: 1px;
            left: 0;
            width: 100%;
            height: 32px;
            line-height: 32px;
            margin-left: 1px;
            background-color: #e9b10e;
            color: #1a1c24;
            font-size: 13px;
            text-align: center;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }
        }

        .list-li {
          // padding: 40px 20px 20px 20px;
        }

        li:hover {
          cursor: pointer;
        }
      }
    }

    .ment-btn {
      margin: 48px 0;
      display: flex;
      justify-content: center;

      .el-btn {
        padding: 14px 26px;
        font-size: 16px;
        color: #1a1c24;
        background-color: #e9b10e;
        border-radius: 5px;
        font-weight: 600;
        border: none;
      }

      /*span:hover {
        cursor: pointer;
      }*/
    }
  }

  .pay-ment /deep/ .el-tabs__item {
    margin-bottom: 6px;
  }

  .pay-ment /deep/ .el-tabs__item.is-active {
    color: #c3c3e2;
  }

  .pay-ment /deep/ .el-tabs__item {
    color: #848492;
    font-size: 20px;
  }

  .el-button--warning {
    background-color: #e9b10e;
    color: #1a1c24;
    font-weight: 600;
  }
}
</style>

<style lang="less">
.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 415px;
  min-width: 300px;
  background-color: #333542;
  color: #c3c3e2;
  font-size: 18px;
  line-height: 44px;
}

.el-dialog__header {
  background-color: #333542;
}

.dialog-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333542;

  div {
    display: flex;
    align-items: center;

    img {
      width: 25px;
      margin: 0 16px 0 0;
    }
  }
}

.el-dialog__body {
  flex: 1;
  overflow: auto;
  background-color: #333542;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2b2c37;

  img {
    max-width: 29px;
    max-height: 29px;
    margin-right: 16px;
  }

  div > p {
    font-size: 12px;
    line-height: 16px;
    display: block;
  }
}

.el-dialog__footer {
  background-color: #2b2c37;
}

.save-qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.img-r0 {
  width: 40% !important;
}

.img-r1 {
  width: 50% !important;
}

.img-r2 {
  width: 60% !important;
}

.img-r3 {
  width: 70% !important;
}

.img-r4 {
  width: 80% !important;
}

.time {
  font-size: 16px;
}

.no-p {
  height: 23px !important;
}

.no-p-1 {
  height: 0 !important;
}

@media screen and (max-width: 767px) {
  .pay .pay-ment .ment-list ul li {
    width: 42% !important;
    // margin: 0 !important;
  }
}
</style>
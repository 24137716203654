module.exports = {
  chainWebpack(config) {
    config.optimization.minimizer('terser').tap((args) => {
      args[0].terserOptions.compress.drop_console = true
      return args
    })
  },
  devServer: {
    ws_config: {
      wsuri: 'wss://www.pxiubox.com/ws/'
    },
    proxy: {
      '/': {
        //此处的写法，目的是为了 将 /api 替换成  http://192.168.101.24:81/
        target: 'http://www.pxiubox.com/',
        // target: 'http://121.199.64.204:82/',
        //target: 'http://94.74.107.150:82',
        // target: 'https://cz-chunxinhuanbao.com/',
        // target: 'http://192.168.101.12:81/',
        //允许跨域
        changeOrigin: true,
        // proxyTimeout:  60 * 1000,
        // onProxyReq: (proxyReq, req, res) => req.setTimeout( 60 * 1000),
        ws: true,
        pathRewrite: {
          '^/': '',
        },
      },
    },
  },
}
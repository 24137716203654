<template>
  <div class="rule">
    <div class="rule-warp">
      <div class="roomleft-num">
        <h5 @click="goopenrule">Blind box unboxing</h5>
        >
        <h6>Rules</h6>
      </div>

      <div class="rule-text">
        <div class="text-title">Unboxing rules</div>
        <p>
          1,
          The gold coins given to new users upon registration can only be opened by new users and newbies. Other gameplay methods require recharging to open the permissions, and there is no limit to withdrawal.
        </p>
        <p>
          2,
          There are two types of box opening probability displays, one is fixed probability and the other is dynamic probability<br>
          Fixed probability: The probability value is fixed and will not change with the opening of the box<br>
          Dynamic probability: The value of dynamic probability changes with the number of box openings and lottery results. If you buy it at the right time, you will have unexpected gains.
        </p>
        <p>
          3.
          The probabilities for new users to open boxes, new users to open boxes, and small trials are dynamic probabilities, while the remaining box probabilities are fixed probabilities.
        </p>
      //</div>

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rule {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;

  .rule-warp {
    background-color: #07172f;
    padding: 16px;
  }
  .rule-text {
    margin-top: 20px;
    .text-title {
      padding: 10px 0;
      color: #c3c3e2;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      color: #bdb0b0;
      padding: 8px 0;
    }
  }
  .rule-input {
    display: table;
    margin-top: 30px;
    padding: 16px;
    background-color: #07172f;
    border-radius: 5px;
    .input-title {
      font-size: 16px;
      color: #070707;
    }
    .input {
      padding: 16px 0;
      input {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        width: 400px;
        color: #0c0c0c;
        font-size: 12px;
        background-color: #07172f;
        outline: none;
        box-sizing: border-box;
        border: none;
      }
      span {
        display: inline-table;
        height: 40px;
        margin-left: 20px;
        line-height: 40px;
        padding: 0 20px;
        background-color: #e9b10e;
        border-radius: 5px;
        font-weight: 600;
        color: #1a1c24;
        font-size: 15px;
      }
      span:hover {
        cursor: pointer;
        background-color: #f5c432;
      }
    }
  }

  .roomleft-num {
    display: flex;
    align-items: center;
    color: #0a0a0a;
    font-size: 16px;

    h5 {
      font-size: 16px;
      font-weight: 200;
    }
    h5:hover {
      cursor: pointer;
      color: #e9b10e;
      text-decoration: underline;
    }
    h6 {
      font-weight: 200;
      color: #c3c3e2;
      font-size: 16px;
    }
  }
}
</style>
<template>
  <div
    class="index"
    :style="{
      backgroundImage: 'url(' + img + ')',
    }"
  >
    <div class="main-top" v-if="isMobile() != 1"    >
      <div class="notice-box">
          <!--banner注释掉-->
        <!-- <marquee>Wellcome!</marquee -->
      </div>
      <!-- <div class="index"> -->
      <!-- <myinform></myinform> -->
      <navheader
        :showNav="showNav"
        :yidongmenu="yidongmenu"
        :openHongbao="openHongbao"
      ></navheader>
      <myslide></myslide>
      <div class="active">
          <div v-for="(item, index) in active" :key="index" class="active-img">
            <a :href="item.url" v-if="item.img" style="display: flex">
              <div v-for="(v, i) in item.img" :key="i">
                <img class="img-1" :src="v" alt="" />
                <img class="img-2" :src="v" alt="" />
              </div>
            </a>
          </div>
          <div style="clear: both"></div>
        </div>
      <div class="index-swiper">
        <swiper :options="swiperOptions" >
          <swiper-slide > <img :src="banners[0]" width="100%;" onclick="javascript:window.location.href='/#/Payment'"/></swiper-slide>
          <swiper-slide> <img :src="banners[1]" width="100%"  onclick="javascript:window.location.href='/#/Lucky'"/></swiper-slide>
          <!-- <swiper-slide> <img :src="banners[2]" width="100%" onclick="javascript:window.location.href='/#/Payment'"/></swiper-slide> -->
          <div class="swiper-pagination"></div>
        </swiper>
      </div>
    </div>
    <!-- mobile -->
    <div class="main-top" v-else >
      <div class="index-swiper_m">
        <swiper :options="swiperOptions" >
          <swiper-slide > <img :src="banners[0]" width="100%;" onclick="javascript:window.location.href='/#/Payment'"/></swiper-slide>
          <swiper-slide> <img :src="banners[1]" width="100%"  onclick="javascript:window.location.href='/#/Lucky'"/></swiper-slide>
          <!-- <swiper-slide> <img :src="banners[2]" width="100%" onclick="javascript:window.location.href='/#/Payment'"/></swiper-slide> -->
          <div class="swiper-pagination"></div>
        </swiper>
      </div>
    </div>

    <div class="center-box">
      <div class="center">
        <div v-for="(v, i) in typeBoxList" :key="i" class="box-area">
          <div class="box">
            <div class="title">
              <span>{{ v.type_name }}</span>
            </div>
            <ul>
              <li
                v-for="(item, index) in v.box_list"
                :key="index"
                class="box-hover"
              >
                <div @click="openbox(item.id)">
                  <div class="bei1">
                    <img :src="item.img_main" />
                  </div>
                  <div class="bei2">
                    <img :src="item.img_active" />
                  </div>
                  <div class="money">
                    <div class="money-warp">
                      <img src="../assets/new/money.png" />
                      <span>{{ item.price }}</span>
                      <!-- <span class="money-buy">打开</span> -->
                      <!-- <span class="money-kong"></span> -->
                    </div>
                  </div>
                  <div class="name">
                    <div class="name-warp">
                      <!--<img src="../assets/img/12mdpi.png" />-->
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </div>
              </li>
              <dir style="clear: both"></dir>
            </ul>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <myhomebot></myhomebot>
    <!--开屏广告-->
    <!-- <div id="mask" ref="mask" v-if="adshow">
      <div class="adshow">
        <img :src="adshowimg" class="mask_img" @click="adClick()" />
      </div>
    </div> -->
  </div>
</template>

<script>

import Utils from "./../assets/js/util.js";
import myslide from "@/components/my_slide1.vue";
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import navheader from "@/components/navheader.vue";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import Swiper2, { Navigation, Pagination, Autoplay } from "swiper";
import { ajaxPrefilter } from "jquery";

Swiper2.use([Navigation, Pagination, Autoplay]);
export default {
  components: { myslide, myhomebot, myinform, Swiper, SwiperSlide, navheader },
  directives: {
    swiper: directive,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      centerContent: [
        {
          name: "商城",
          image: require("../assets/img/nav2/b55.png"),
          url: "/Roll",
          type: 1,
        },
        {
          name: "roll房",
          image: require("../assets/img/nav2/b11.png"),
          url: "/Arena",
          type: 1,
        },
        {
          name: "CD-KEY",
          image: require("../assets/img/cdk.png"),
          url: "/Payment?type=cdk",
          type: 1,
        },
        {
          name: "客服",
          image: require("../assets/img/kefu.png"),
          type: 2,
        },
        {
          name: "取回助手",
          image: require("../assets/img/back.svg"),
          type: 3,
        },
      ],
      typeBoxList: [],
      listSlide: [],
      img: "",
      img1: require("../assets/img/1mdpi.png"),
      active: [],
      windowWidth: document.body.clientWidth,
      userState: "",
      banners: [
        require("../assets/95/banner1.png"),
        require("../assets/95/banner2.png"),
      ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        autoplay: {
          delay: 3000, // 多长时间进行一次自动切换
          disableOnInteraction: false, // 用户点击一次后是否取消自动切换 默认true
        },
        loop: true,
      },
      adshow: false,
      adshowimg: require("../assets/95/adshow.png"),
    };
  },

  methods: {
    bindNav(type, url) {
      if (type == 2) {
        window.open(
        "https://tawk.to/chat/66152b841ec1082f04e072de/1hr1b40d5"
        );
      }
      if (type == 1) {
        this.$router.push({ path: url });
      }
      if (type == 3) {
        this.$emit("backAction", true);
      }
    },
    adClick() {
      this.adshow = false;
      this.$store.commit("getLogin", true);
    },
    //请求背景图片
    getBack() {
      let _this = this;
      _this.$axios.post("/index/Setting/background").then((res) => {
        if (res.data.status == 1) {
          this.img = res.data.data.img;
          if (!this.img) {
            this.img = this.img1;
          }
        }
      });
    },
    // 首页活动模块
    getboxList() {
      let _this = this;
      _this.$axios.post("/index/Box/boxList").then((res) => {
        this.active = res.data.data.active;
        if (res.data.status == 1) {
          _this.typeBoxList = res.data.data.list;
          //console.log(_this.typeBoxList);
        }
      });
    },
    openbox(box_id) {
      this.$router.push({
        path: `/Openbox`,
        query: {
          box_id: box_id,
        },
      });
    },
    getPlayerInfo(pid) {
      let _this = this;
      let param = {
        player_id: pid,
      };
      if (param.player_id) {
        _this.$axios
          .post("/index/User/getPlayerInfo", _this.$qs.stringify(param))
          .then((res) => {
            // console.log(res.data.data);
            if (res.data.data.status == 1) {
              // console.log(res.data.data.total_amount);
              // Utils.$emit("money", res.data.data.total_amount);
              // Utils.$emit("state", res.data.data.state);
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              Utils.$emit("login", true);
            }
          });
      }
    },
    isMobile() {
      let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      return flag;
    },
    getBgi(v) {
      const index = {
        系列武器箱: "1",
        血压五五开: "2",
        一触即发: "3",
        帝王之星: "4",
        热门推荐: "5",
        福利箱子: "6",
      };
      return index[v.type_name];
    },
  },
  mounted() {
    if (this.$route.query.pid > 0) {
      // Utils.$emit('pid',_this.$route.query.pid)
      console.log(this.$route.query.pid);
      this.getPlayerInfo(this.$route.query.pid);
    }
    this.getboxList();
    this.getBack();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      this.adshow = true;
      return;
    }
    this.userState = userInfo.state;
    // this.$emit('backAction','click')
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "numFont";
  src: url("../assets/font/numfont.ttf");
}
@font-face {
  font-family: "ninefont";
  src: url("../assets/font/ninefont.ttf");
}
.main-top {
  /*首页图片*/
  background: url("../assets/95/header.png") no-repeat;
  background-color: #07172f;
  width: 100%;
  overflow: hidden;
  //margin-top: 10px;
  background-size: 100% 100%;
  border-bottom: 0.16vw solid #ffc400;
  padding-bottom: 15vw;
 // margin-bottom: 20px;
}
.notice-box {
  width: 72.92vw;
  margin: 10vw auto 7.81vw;
  position: relative;
  marquee {
    color: #fff;
    font-family: 宋体, SimSun;
    font-size: 16px;
  }
  span {
    color: #fff;
    font-size: 12px;
    display: inline-block;
    width: 50px;
    text-align: center;
  }
}
.index-swiper {
  width: 62.5vw;
  margin: 0 auto;
  cursor: pointer;
  transform: translate(0, 150%);
  img {
    max-height: 260px;
  }
}
.index-swiper_m {
  width: 62.5vw;
  margin: 0 auto;
  cursor: pointer;
  transform: translate(0, 20%);
  img {
    max-height: 260px;
  }
}
.index {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background: url("../assets/img/bg.png") no-repeat;
  //background-color: #07172f;

  //--------
  // position:absolute;
  // top:0;
  // left:0;
  // height:calc(100vh);
  width: 100%;
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover; /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover; /* 兼容Opera */
  zoom: 1;

  /* .top::-webkit-scrollbar {
        display: none;
  }*/
  // ::after {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   content: "";
  //   background-color: yellow;
  //   opacity: 0.2;
  //   z-index: 1;
  //   width: 100%;
  //   height: 100%;
  // }

  .yidongcenter {
    background: url("../assets/img/newback.png");
    background-color: #b19797;
    background-size: 400% !important;
    background-position: -410px -100px !important;
  }
  .center-box {
    background: url("../assets/img/bg.png") no-repeat;
    background-color: #07172f;
    background-position: top;
    background-size: cover;
    border-top: 1px solid rgba(0, 0, 0, 0);
    position: relative;
    min-height: 100vh;
  }
  .center {
    width: 72.92vw;
    height: 80vw;
    margin: 2.08vw auto 0;
    position: relative;
    .box-area {
      .box {
        margin-top: -20px;
      }
    }
    .box {
      ul {
        dir {
          margin-top: -40px;
          color: #070707;
        }
      }
      .title {
        font-size: 24px;
        font-weight: 700;
        color: #070707;
        width: 260px;
        height: 40px;
        line-height: 40px;
        background: url("../assets/new/titlebg.png") no-repeat;
        background-size: 100% 100%;
        margin: 30px auto;
        margin-bottom: 30px;
        font-family: ninefont;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      ul {
        padding: 0 4%;
        li {
          // width: 20%;
          float: left;
          position: relative;
          width: 22.1vw;
          height: 27.5vw;
          .bei1 {
            // display: flex;
            justify-content: center;
            transition: all 500ms;
            // height: 75.7%;
            background: url("../assets/new/bg.gif") no-repeat;
            background-size: 100% 100%;
            img {
              width: 100%;
              // height: 300px;
            }
          }
          .bei2 {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            top: 0;
            transition: all 500ms;

            img {
              width: 60%;
              height: 60%;
            }
          }

          .name {
            display: flex;
            justify-content: center;
            .name-warp {
              display: flex;
              align-items: center;
              // color: #c3c3e2;
              color: #fff;
              font-size: 1.15vw;
              // font-weight: 600;
              img {
                width: 25px;
                height: 25px;
                margin-right: 10px;
              }
            }
          }
          .money {
            margin-top: 5px;
            display: flex;
            justify-content: center;
            .money-warp {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #ffc400;
              font-size: 1.15vw;
              font-weight: 700;
              // border: 2px solid #ff9b0b;
              // background-color: #8dc0dd;
              // background:linear-gradient(to right, #FF571B 0%, #CE34C1 100%) border-box;
              overflow: hidden;
              border-radius: 20px;
              padding: 6px 16px;
              font-family: numFont;
              //boder颜色渐变
              // border: 2px transparent solid;
              // background-clip: padding-box, border-box;
              // background-origin: padding-box, border-box;
              // background-image: linear-gradient(135deg,rgba(25,30,46,0.8),rgba(255, 255, 255, 0.5)), linear-gradient(135deg, #ff571b, #ff9b0b);
              // background-color:transparent;

              img {
                width: auto;
                height: 20px;
                margin-right: 5px;
              }
            }
          }
        }
      }

      //动画
      .money-buy {
        display: none;
      }
      .money-kong {
        display: none;
      }
      .box-hover:hover {
        cursor: pointer;
        .bei2 {
          transform: translateY(-20px);
        }
        .bei1 {
          transform: scale(1.1);
        }
        .money-warp {
          color: #000000;
          background-color: #fbfa02;
        }
      }
      .box-hover:hover .money-buy {
        // display: block;
        position: absolute;
        right: -1px;
        // background-color: #FF9B0B;
        // background-image: linear-gradient(to right, #ff571b, #ff9b0b);
        background-color: #fbfa02;
        border-radius: 20px;
        color: white;
        padding: 7px 16px;
        color: #121720b0;
      }
      .box-hover:hover .money-kong {
        display: block;
        margin-left: 60px;
      }
      .box-hover:hover .bei2 {
        position: absolute;
        top: 0;
        // animation: boxhover 1.5s linear 0s infinite alternate;
      }
      @keyframes boxhover {
        0% {
          top: 20%;
        }
        50% {
          top: 30%;
        }
        100% {
          top: 20%;
        }
      }
    }
  }
}
.center-Content {
  display: flex;
  align-content: center;
  color: hsla(0, 0%, 100%, 0.5);
  margin: 0 -12px;
  justify-content: space-between;
  .first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
  }
  img {
    width: 25px;
  }
}
.active {
  // width: 40%;
  margin: 20px auto;
  position: relative;
  // display: flex;
  // float: left;
  .active-img {
    width: calc(33.33333% - 5px);
    position: relative;
    margin-left: 2.5px;
    margin-right: 2.5px;
    float: left;
    :hover {
      // transform: translate3d(0,-10px,0);
      // transition: transform 1s;
      animation: imghover 1s infinite;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
  .img-1 {
    position: absolute;
    cursor: pointer;
  }
  .img-2 {
    visibility: hidden;
  }
  .img-1,
  .img-2 {
    // width: inherit;
    width: 100%;
    height: 90px;
    object-fit: cover;
  }

  @keyframes imghover {
    from {
      top: 0px;
    }
    to {
      top: -15px;
    }
  }
}
#mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #07172f;
  display: flex;
  justify-content: center;
  align-items: center;
  .adshow {
    background-color: #07172f;
    // width: 19.22vw;
    // height: 25.52vw;
    // position: relative;
    img {
      width: 100%;
    }
  }
}
// .mask_img {
//   width: 50%;
// }

@media screen and (max-width: 768px) {
  .active {
    width: 98%;
  }
  .center {
    .box {
      ul {
        padding: 0 !important;
      }
      .title {
        font-size: 20px !important;
      }
    }
  }
  // .mask_img {
  // width: 100%;
  // }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .active-img {
    width: calc(50% - 5px) !important;
  }
  .center {
    .box {
      .title {
        font-size: 26px !important;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .active-img {
    width: 100% !important;
  }
}
</style>

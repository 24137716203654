<template>
  <div class="homebot"  v-if="isMobile() != 1">
    <!-- <div class="top">
      <div class="top-left">
        <ul>
          <li v-for="(item, index) in routerList"  :key="index">
            <span @click="fun(item.url)">{{ item.name }}</span>
          </li>
          <div class="top-right" @click="goCase()" style="cursor:pointer">
            <span>赣ICP备2023002526号-1 </span>
          </div>
        </ul>
      </div>
      <div class="top-right">
        <img src="../assets/img/weixin_back.png">
        <img src="../assets/img/zhifubao_back.png">
      </div>
    </div> -->
    <div class="bg-mask"></div>
    <div class="footer-content">
      <img
        class="placeholder-img"
        src="../assets/img/footer/footer-bg.png"
        alt=""
        srcset=""
      />
      <div class="platform-info">
        <div class="item">
          <span>{{ 100000 + info.openbox_num }}</span>
          <span>Total Mystery Boxes Opened</span>
        </div>
        <div class="item center">
          <span> {{ 10000 + info.player_num }}</span>
          <span>Registered Users</span>
        </div>
        <div class="item">
          <span>{{ 4300 +info.online_num }}</span>
          <span>Online Users</span>
        </div>
      </div>
      <ul class="list">
        <li
          v-for="(item, index) in routerList"
          @click="fun(item.url)"
          :key="`${item.url}-${index}`"
        >
          <span class="link-name">{{ item.name }}</span>
          <span v-if="index + 1 != routerList.length">|</span>
        </li>
      </ul>
      <!-- 微信支付与备案号 -->
      <!-- <div class="copyright">
        <span class="ba-right" @click="goCase()"
          >备案号：赣ICP备2023002526号-1</span>
        <div class="top-right">
          <img src="../assets/img/weixin_back.png" />
          <img src="../assets/img/zhifubao_back.png" />
        </div>
      </div> -->
      <ul class="list">
        <li v-for="(item, index) in imgLinkList" :key="item.img">
          <a :href="item.link"> <img :src="item.img" alt="" srcset="" style="width: 60px" /> </a> 
        </li>
      </ul>
    </div>
  </div>
  <div class="homebot_m" v-else>
    <!-- <div class="top">
      <div class="top-left">
        <ul>
          <li v-for="(item, index) in routerList"  :key="index">
            <span @click="fun(item.url)">{{ item.name }}</span>
          </li>
          <div class="top-right" @click="goCase()" style="cursor:pointer">
            <span>赣ICP备2023002526号-1 </span>
          </div>
        </ul>
      </div>
      <div class="top-right">
        <img src="../assets/img/weixin_back.png">
        <img src="../assets/img/zhifubao_back.png">
      </div>
    </div> -->
    <div class="bg-mask"></div>
    <div class="footer-content">
      <img
        class="placeholder-img"
        src="../assets/img/footer/footer-bg.png"
        alt=""
        srcset=""
      />
      <div class="platform-info">
        <div class="item">
          <span>{{ 100000 + info.openbox_num }}</span>
          <span>Total Mystery Boxes Opened</span>
        </div>
        <div class="item center">
          <span>{{ 10000+ info.player_num }}</span>
          <span>Number of Registered Users</span>
        </div>
        <div class="item">
          <span>{{ 4300 + info.online_num }}</span>
          <span>Online Users</span>
        </div>
      </div>
      <ul class="list">
        <li
          v-for="(item, index) in routerList"
          @click="fun(item.url)"
          :key="`${item.url}-${index}`"
        >
          <span class="link-name">{{ item.name }}</span>
          <span v-if="index + 1 != routerList.length">|</span>
        </li>
      </ul>
      <!-- 微信支付与备案号 -->
      <!-- <div class="copyright">
        <span class="ba-right" @click="goCase()"
          >备案号：赣ICP备2023002526号-1</span>
        <div class="top-right">
          <img src="../assets/img/weixin_back.png" />
          <img src="../assets/img/zhifubao_back.png" />
        </div>
      </div> -->
      <ul class="list">
        <li v-for="(item, index) in imgLinkList" :key="item.img">
          <img :src="item.img" alt="" srcset="" style="width: 100px" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routerList: [
        // 关于我们
        { name: "About Us", url: "Abouts" },
        { name: "User Agreement", url: "Agreement" },
        { name: "Privacy Policy", url: "Privacy" },
        // { name: "Terms & Conditions", url: "Clause" },
        // { name: "FAQs", url: "Doubt" },
        // { name: "营业执照", url: "Doubt" },
      ],
      urlList: [],
      imgLinkList: [
        // 首页信任标识
        {
          img: require("../assets/img/telegram.png"),
          link: "https://t.me/+meqLztVR9uplZWY1",
        },
        {
          img: require("../assets/img/twitter.png"),
          link: "https://twitter.com/@luckcatsome",
        },
        {
          img: require("../assets/img/gmail.png"),
          link: "mailto:pxiuboxofficial01@gmail.com",
        }
      ],
      info: {
        player_num: 0,
        online_num: 0,
        openbox_num: 0,
      },
    };
  },
  created() {
    this.$axios
      .post("/index/Login/logininfo", this.$qs.stringify({}))
      .then((res) => {
        if (res.data.status) {
          this.info = res.data.data;
        }
      });
  },
  methods: {
    fun(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
    goCase() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    isMobile() {
      let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      return flag;
    }
  },
};
</script>

<style lang="less" scoped>
.homebot {
  padding: 10px;
  height: 10px;
  position: relative;
  .bg-mask {
    background-color: #07172f;
    filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .copyright {
    max-width: 800px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ba-right {
      color: #878787;
      font-size: 12px;
      cursor: pointer;
    }
    .top-right {
      margin-top: 20px;
    }
  }

  .footer-content {
    position: absolute;
    //top: 0;
    left: 0;
    right: 0;
    //bottom: 0;
    background: transparent;
    background-color: #07172f;
    color: #bdb0b0;
    .placeholder-img {
      width: 100%;
    }
    .list {
      max-width: 800px;
      margin: 20px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      img {
        cursor: pointer;
        margin-bottom: 10px;
      }
      li {
        margin-right: 10px;
        font-size: 12px;

        .link-name {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
    .platform-info {
      max-width: 800px;
      margin: 40px auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100px;
        font-size: 14px;
        & > span:nth-child(1) {
          font-weight: bold;
          font-size: 16px;
        }
      }
      .center {
        position: relative;
        &::before {
          display: block;
          content: "";
          width: 1px;
          height: 100px;
          position: absolute;
          left: 0;
          top: 0;
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(hsla(0, 0%, 100%, 0.1)),
            color-stop(#ccc),
            to(hsla(0, 0%, 100%, 0.1))
          );
          background-image: -webkit-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
          background-image: -o-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
          background-image: linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
        }
        &::after {
          display: block;
          content: "";
          width: 1px;
          height: 100px;
          position: absolute;
          right: 0;
          top: 0;
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(hsla(0, 0%, 100%, 0.1)),
            color-stop(#ccc),
            to(hsla(0, 0%, 100%, 0.1))
          );
          background-image: -webkit-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
          background-image: -o-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
          background-image: linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
        }
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #848492;
    .top-left {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          margin-top: 10px;
          margin-right: 32px;
          span {
            white-space: nowrap;
            display: inline-block;
          }
        }
        li:hover {
          cursor: pointer;
        }
      }
    }
    .top-right {
      margin-top: 10px;

      img {
        margin-right: 10px;
      }
    }
  }
  .bot {
    margin-top: 24px;
    display: flex;
    font-size: 14px;
    color: #515260;
    overflow-y: hidden;
    .bot-title {
      margin-right: 12px;
    }
    ul {
      display: flex;
      li {
        margin-right: 24px;
      }
      li:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #e9b10e;
      }
    }
  }
}
.homebot_m {
  padding: 150px;
  height: 10px;
  position: relative;
  .bg-mask {
    //background-color: #07172f;
    filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .copyright {
    max-width: 800px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ba-right {
      color: #878787;
      font-size: 12px;
      cursor: pointer;
    }
    .top-right {
      margin-top: 20px;
    }
  }

  .footer-content {
    position: absolute;
    //top: 0;
    left: 0;
    right: 0;
    //bottom: 0;
    background: transparent;
    background-color: #07172f;
    color: #bdb0b0;
    .placeholder-img {
      width: 100%;
    }
    .list {
      max-width: 800px;
      margin: 20px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      img {
        cursor: pointer;
        margin-bottom: 10px;
      }
      li {
        margin-right: 10px;
        font-size: 12px;

        .link-name {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
    .platform-info {
      max-width: 800px;
      margin: 40px auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100px;
        font-size: 14px;
        & > span:nth-child(1) {
          font-weight: bold;
          font-size: 16px;
        }
      }
      .center {
        position: relative;
        &::before {
          display: block;
          content: "";
          width: 1px;
          height: 100px;
          position: absolute;
          left: 0;
          top: 0;
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(hsla(0, 0%, 100%, 0.1)),
            color-stop(#ccc),
            to(hsla(0, 0%, 100%, 0.1))
          );
          background-image: -webkit-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
          background-image: -o-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
          background-image: linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
        }
        &::after {
          display: block;
          content: "";
          width: 1px;
          height: 100px;
          position: absolute;
          right: 0;
          top: 0;
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(hsla(0, 0%, 100%, 0.1)),
            color-stop(#ccc),
            to(hsla(0, 0%, 100%, 0.1))
          );
          background-image: -webkit-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
          background-image: -o-linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
          background-image: linear-gradient(
            hsla(0, 0%, 100%, 0.1),
            #ccc,
            hsla(0, 0%, 100%, 0.1)
          );
        }
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #848492;
    .top-left {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          margin-top: 10px;
          margin-right: 32px;
          span {
            white-space: nowrap;
            display: inline-block;
          }
        }
        li:hover {
          cursor: pointer;
        }
      }
    }
    .top-right {
      margin-top: 10px;

      img {
        margin-right: 10px;
      }
    }
  }
  .bot {
    margin-top: 24px;
    display: flex;
    font-size: 14px;
    color: #515260;
    overflow-y: hidden;
    .bot-title {
      margin-right: 12px;
    }
    ul {
      display: flex;
      li {
        margin-right: 24px;
      }
      li:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #e9b10e;
      }
    }
  }
}
</style>
